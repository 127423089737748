<template>
  <div>
    <div class="mb-2">
      <b-breadcrumb
        :items="breadcrumbItems"
      />
    </div>
    <div class="d-flex">
      <h2>プロフィール</h2>
    </div>
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body mt-1 mb-2">
        <div class="d-flex">
          <b-avatar
            size="40"
            src="https://firebasestorage.googleapis.com/v0/b/protex-b9e4c.appspot.com/o/brand%2Fwoman-intro.png?alt=media&token=998c4413-ebc2-46cc-a62d-16c0565dfe32"
            variant="light-primary"
            class="mr-50"
          />
          <div class="ml-50">
            動画を視聴するためにはプロフィールの入力が必要です。
          </div>
        </div>
      </div>
    </b-alert>
    <b-card
      title=""
    >
      <b-row
        v-for="(item, index) in menulist"
        :key="index"
      >
        <InputParts
          v-if="useValueDicList.includes(item.type)"
          :label="item.label"
          :type="item.type"
          :choice="typeof item.choice !== 'undefined'? item.choice: []"
          :value-dic="inputForm[item.key]"
          :disabled="typeof item.disabled !== 'undefined'? item.disabled: false"
          :default-value="typeof item.defaultValue !== 'undefined'? item.defaultValue: []"
          :explain="typeof item.explain !== 'undefined'? item.explain: []"
          :select="item.type ==='checkbox' ? choice: []"
          @inputData="inputForm[item.key] = $event"
        />
        <InputParts
          v-else-if="useValueListList.includes(item.type)"
          :label="item.label"
          :type="item.type"
          :choice="typeof item.choice !== 'undefined'? item.choice: []"
          :value-list="typeof inputForm[item.key] !== 'undefined'? inputForm[item.key] : []"
          :default-value="typeof item.defaultValue !== 'undefined'? item.defaultValue: []"
          :disabled="typeof item.disabled !== 'undefined'? item.disabled: false"
          :explain="typeof item.explain !== 'undefined'? item.explain: []"
          :select="item.type === 'checkboxplain' ? item.select: []"
          @inputData="inputForm[item.key] = $event"
        />
        <InputParts
          v-else
          :label="item.label"
          :type="item.type"
          :choice="typeof item.choice !== 'undefined'? item.choice: []"
          :value="item.type !=='checkbox' ?inputForm[item.key]: ''"
          :disabled="typeof item.disabled !== 'undefined'? item.disabled: false"
          :explain="typeof item.explain !== 'undefined'? item.explain: []"
          :select="item.type ==='checkbox' ? choice: []"
          @inputData="inputForm[item.key] = $event"
        />
      </b-row>
      <b-row class="mt-2">
        <b-col offset-md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="text-secondary"
            to="/student/dashboad"
          >
            <feather-icon
              icon="ChevronsLeftIcon"
              class="mr-50"
            />
            戻る
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="ml-50"
            @click="confirm"
          >
            <feather-icon
              icon="UploadCloudIcon"
              class="mr-50"
            />
            登録する
            <b-spinner
              v-if="status === 2"
              small
            />
          </b-button>
        </b-col>
      </b-row>
      <div v-if="errorList.length > 0">
        <b-alert
          variant="danger"
          show
          class="alertSpace"
        >
          <div class="alert-body">
            <div class="">
              {{ errorMSG }}
            </div>
            <div
              v-for="(item, index) in errorList"
              :key="index"
              class=""
            >
              ・<b>{{ item }}</b>
            </div>
          </div>
        </b-alert>
      </div>
    </b-card>
    <SuccessBasic
      :show-alert="isShowModal"
      to-page="student-dashboad"
    />
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BButton, BCard, BSpinner, BAlert, BBreadcrumb, BAvatar,
} from 'bootstrap-vue'
/* eslint-disable */
import menulist from '@/components/conf/student/学習PF_受講生_プロフィール.json'
/* eslint-enable */
import InputParts from '@/components/molecules/input/InputParts.vue'
import SuccessBasic from '@/components/ui/alert/SuccessBasic.vue'
import choice from '@/components/conf/JPCAREER様_学習PF_選択肢.json'
import { ref, onMounted } from '@vue/composition-api'
import useProfile from '@/components/js/compositionAPI/profile/useProfile'
import useData from '@/components/js/compositionAPI/data/useData'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    InputParts,
    BSpinner,
    BAlert,
    BBreadcrumb,
    BAvatar,
    SuccessBasic,
  },
  directives: {
    Ripple,
  },
  setup() {
    const inputForm = ref({})
    const status = ref(1)
    const isShowModal = ref(false)
    const useValueDicList = ['checkbox', 'selectSingle', 'cancelPolicy', 'radio']
    const useValueListList = ['checkboxplain', 'qualificationInput', 'serviceCategory', 'languageLevelSelect']
    const errorMSG = ref('')
    const errorList = ref([])
    const { getProfileSingleByUid, updateProfile } = useProfile()
    const { isInvalid } = useData()
    const breadcrumbItems = ref([
      {
        text: 'ダッシュボード',
        to: { name: 'student-dashboad' },
        icon: 'HomeIcon',
      },
      {
        text: 'プロフィール',
        active: true,
        key: 'subTitle',
      },
    ])

    const confirm = async () => {
      status.value = 2
      const d = {
        menulist,
        inputForm: inputForm.value,
      }
      const validation = isInvalid(d)
      if (validation.isInvalid) {
        errorMSG.value = validation.errorMSG
        errorList.value = validation.errorList
      } else {
        const res = await updateProfile({ data: inputForm.value })
        if (res.status === 'success') isShowModal.value = true
      }
      status.value = 1
    }
    const fetch = async () => {
      inputForm.value = await getProfileSingleByUid()
    }
    onMounted(() => {
      fetch()
    })
    return {
      inputForm,
      status,
      isShowModal,
      menulist,
      choice,
      breadcrumbItems,
      useValueDicList,
      useValueListList,
      errorMSG,
      errorList,
      confirm,
    }
  },
}
</script>
